<template>
  <picture>
    <img
      class="logo"
      :src="logoPath"
      :width="width"
      :height="height"
      :alt="customerPromotionAffiliate"
    />
  </picture>
</template>

<script>
export default {
  computed: {
    customerPromotionAffiliate() {
      return (
        this.$store.state.customer.affiliate ||
        this.$route.params.promotion ||
        'F-Secure'
      )
    },
    uiBrand() {
      return this.$store.state.ui.brand
    },
    height() {
      let height = this.$store.state.ui.logoHeight

      if (height) {
        return height
      }

      return 'auto'
    },
    width() {
      let width = this.$store.state.ui.logoWidth

      if (width) {
        return width
      }

      return 'auto'
    },
    logoPath() {
      return this.$store.state.ui.logoPath
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  vertical-align: middle;
  max-height: 48px;
}
</style>
