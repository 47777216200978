<template>
  <base-header-nav-row class="mobile-only log-out">
    <div>
      <span>{{ $t('dashboard.logged_in_as', { name: loggedInAs }) }}</span>
    </div>
    <div>
      <a
        id="mobile-logout-link"
        data-track-event="navigate"
        data-track-name="logout"
        @click="saveLoggedOutOnce"
        href="/logout"
      >
        <span>{{ $t('dashboard.lnk_log_out') }}</span>
      </a>
    </div>
  </base-header-nav-row>
</template>

<script>
import BaseHeaderNavRow from './BaseHeaderNavRow.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    BaseHeaderNavRow
  },
  computed: {
    ...mapGetters({
      fullName: 'customer/fullName'
    }),
    ...mapState({
      customerEmail: (state) => state.customer.email
    }),
    loggedInAs() {
      if (this.fullName.length > 0) {
        return this.fullName
      }

      return this.customerEmail
    }
  },
  methods: {
    saveLoggedOutOnce() {
      this.$cookies.set('logged_out', 1, '1y')
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-row {
  &.log-out {
    display: flex;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &:nth-child(1) {
        width: 66.66%;
        padding: 0 30px;

        span {
          line-height: 1.3rem;
        }

        &::after {
          content: '';
          display: block;
          height: 100%;
          width: 1px;
          position: absolute;
          top: 0;
          right: 0;
          background-image: linear-gradient(#e9e9e9, #e9e9e9);
          background-size: 1px 25px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &:nth-child(2) {
        width: 33.33%;

        a {
          text-decoration: none;
          padding: 15px 30px;
          text-align: center;
          position: relative;
          display: inline-block;
          width: 100%;
          line-height: 1.3rem;

          span {
            color: $primary-color;
          }
        }
      }
    }
  }
}
</style>
