var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-header-nav-row", { staticClass: "mobile-only log-out" }, [
    _c("div", [
      _c("span", [
        _vm._v(
          _vm._s(_vm.$t("dashboard.logged_in_as", { name: _vm.loggedInAs }))
        ),
      ]),
    ]),
    _c("div", [
      _c(
        "a",
        {
          attrs: {
            id: "mobile-logout-link",
            "data-track-event": "navigate",
            "data-track-name": "logout",
            href: "/logout",
          },
          on: { click: _vm.saveLoggedOutOnce },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("dashboard.lnk_log_out")))])]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }