var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-header-nav-row",
    { staticClass: "logo mobile-only" },
    [_c("myfs-logo")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }