<template>
  <a
    id="dashboard-toggle-mobile"
    href="#"
    data-track-event="navigate"
    data-track-name="total-dashboard"
    @click.prevent="toggleTopMenu"
    class="myfs-top-menu-link"
    :class="{ 'is-shown': isShown, 'menu-open': topMenuOpened }"
  >
    <span class="burger-menu">
      <img src="@/assets/images/icons/icon_burger_menu.svg" alt />
      <img src="@/assets/images/icons/dashboard_icon_close.svg" alt />
    </span>
  </a>
</template>
<script>
export default {
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$root.$on('popup-opened', this.markTopMenuOpened)
    this.$root.$on('popup-closed', this.markTopMenuClosed)
  },
  beforeDestroy() {
    this.$root.$off('popup-opened', this.markTopMenuOpened)
    this.$root.$off('popup-closed', this.markTopMenuClosed)
  },
  data() {
    return {
      topMenuOpened: false
    }
  },
  methods: {
    toggleTopMenu() {
      this.$root.$emit('toggle_popup', {
        elementId: 'top-menu',
        eventInitiator: this.$el
      })
    },
    markTopMenuOpened(elementId) {
      if (elementId === 'top-menu') {
        this.topMenuOpened = true
      }
    },
    markTopMenuClosed(elementId) {
      if (elementId === 'top-menu') {
        this.topMenuOpened = false
      }
    }
  }
}
</script>
<style lang="scss">
.myfs-top-menu-link {
  height: 20px;
  padding: 0 5px;
  vertical-align: top;
  display: none;

  &.is-shown {
    display: inline-block;
  }

  &.menu-open {
    .burger-menu {
      img {
        &:first-child {
          opacity: 0;
          transform: rotate(45deg) scale(0.5);
        }
        &:last-child {
          opacity: 1;
          transform: rotate(0) scale(1);
        }
      }
    }
  }

  .burger-menu {
    width: 24px;
    height: 20px;
    display: inline-block;
    position: relative;
    vertical-align: top;

    img {
      position: absolute;
      transition-property: opacity, transform;
      transition-duration: 0.3s, 0.3s;

      &:first-child {
        width: 24px;
        height: 19px;
      }

      &:last-child {
        opacity: 0;
        transform: rotate(-45deg) scale(0.5);
      }
    }
  }
}

@media (min-width: $desktop-min-width) {
  .myfs-top-menu-link {
    display: none;

    &.is-shown {
      display: none;
    }
  }
}
</style>
