<template>
  <nav
    id="top-menu"
    class="the-header-nav"
    :class="{
      'is-open': isOpen,
      'three-cards': !isSenseCardShown && !isSenseSdkCardShown,
      'hide-on-desktop': true
    }"
    @mouseenter="onMouseEnter('#the-overlay')"
    v-show="$props.isVisible"
    data-body-class-on-open="top-menu-open"
  >
    <a class="close" href="#" @click.prevent="closePopup()">
      <span>{{ $t('dashboard.lnk_close').toLowerCase() }}</span>
      <span>
        <img src="../assets/images/icons/dashboard_icon_close.svg" alt="x" />
      </span>
    </a>
    <div class="content-wrapper">
      <template>
        <template v-if="isIDPSubscription">
          <key-product-link is-mobile-only />
          <idp-monitor-link is-mobile-only v-if="showMonitoring" />
        </template>
        <template v-else-if="isFreedomeSubscription">
          <freedome-product-link is-mobile-only />
        </template>
        <template v-else>
          <safe-product-link is-mobile-only />
          <idp-monitor-link is-mobile-only v-if="showMonitoring" />
        </template>

        <coupon-share-link
          v-for="sharedProductShortName in sharedProductShortNames"
          :key="sharedProductShortName"
          :product-short-name="sharedProductShortName"
        />
      </template>
      <mobile-logout />
      <mobile-grid-links />
      <mobile-logo />
    </div>
  </nav>
</template>
<script>
import popupMixin from '@/mixins/popup.js'
import { mapGetters, mapState } from 'vuex'
import SafeProductLink from './TheHeaderNavSafeProductLink.vue'
import FreedomeProductLink from './TheHeaderNavFreedomeProductLink.vue'
import KeyProductLink from './TheHeaderNavKeyProductLink.vue'
import IdpMonitorLink from './TheHeaderNavIdpMonitorLink.vue'
import MobileLogout from './TheHeaderNavMobileLogout.vue'
import MobileGridLinks from './TheHeaderNavMobileGridLinks.vue'
import MobileLogo from './TheHeaderNavMobileLogo.vue'
import CouponShareLink from './TheHeaderNavCouponShareLink.vue'

export default {
  mixins: [popupMixin],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isOpen(newValue, OldValue) {
      this.$root.$emit('toggle_popup', {
        elementId: 'the-overlay',
        action: newValue ? 'open' : 'close',
        eventInitiator: this.$el
      })
    }
  },
  components: {
    SafeProductLink,
    FreedomeProductLink,
    KeyProductLink,
    IdpMonitorLink,
    MobileLogout,
    MobileGridLinks,
    MobileLogo,
    CouponShareLink
  },
  computed: {
    ...mapState({
      customerIdentifier: (state) => state.customer.identifier,
      daysUntilExpiration: (state) =>
        state.customer.contract.daysUntilExpiration,
      licenseSize: (state) => state.customer.contract.licenseSize,
      customerIsLoggedIn: (state) => state.customer.isLoggedIn,
      isReminderPopupOpen: (state) => state.ui.isReminderPopupOpen,
      isRegistrationSuccessPopupOpen: (state) =>
        state.ui.isRegistrationSuccessPopupOpen,
      serviceKey: (state) => state.customer.contract.serviceKey,
      productVariant: (state) => state.customer.contract.productVariant
    }),
    ...mapGetters({
      isIDPSubscription: 'customer/contract/isIDPSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      isIdmInstallable: 'customer/licenses/isIdmInstallable',
      isPrepaid: 'customer/contract/isPrepaid',
      isSafeSubscription: 'customer/contract/isSafeSubscription',
      isSenseCardShown: 'ui/isSenseCardShown',
      isSenseSdkCardShown: 'ui/isSenseSdkCardShown',
      isTotalSubscription: 'customer/contract/isTotalSubscription',
      isTrial: 'customer/contract/isTrial',
      sharedProductShortNames: 'customer/sharedProductShortNames',
      isUnified: 'customer/isUnified'
    }),
    // unified subscription does not support dashboard
    isPrepaidSafe() {
      return this.isSafeSubscription && this.isPrepaid
    },
    showIDPWeb() {
      return this.$store.state.ui.showIDPWeb
    },
    showMonitoring() {
      return this.isIdmInstallable && this.showIDPWeb
    }
  },
  mounted() {
    this.$root.$on('customer-api-call-finished', this.emitPurchaseIfOccured)
    this.emitPurchaseIfOccured()
  },
  beforeDestroy() {
    this.$root.$off('customer-api-call-finished', this.emitPurchaseIfOccured)
  },
  methods: {
    emitPurchaseIfOccured() {
      let hasPurchaseOccured = this.hasPurchaseOccured()
      if (hasPurchaseOccured) {
        let purchaseType = 'renew'
        if (this.hasTrialBeenUpgraded() || this.hasProductChanged()) {
          purchaseType = 'upgrade'
        }
        if (this.hasLicenseSizeIncreased()) {
          purchaseType = 'buy-more'
        }
        if (this.hasDaysUntilExpirationIncreased()) {
          purchaseType = 'renew'
        }
        this.$root.$emit('purchase-occured', purchaseType)
      }
      this.setNewCookieValues()
    },
    hasPurchaseOccured() {
      return (
        this.hasDaysUntilExpirationIncreased() ||
        this.hasLicenseSizeIncreased() ||
        this.hasTrialBeenUpgraded() ||
        this.hasProductChanged()
      )
    },
    setNewCookieValues() {
      this.$cookies.set(
        this.customerIdentifier + '_days_until_expiration',
        this.daysUntilExpiration
      )
      this.$cookies.set(
        this.customerIdentifier + '_is_trial',
        this.isTrial === true ? 1 : 0
      )
      if (this.isUnified) {
        this.$cookies.set(
          this.customerIdentifier + '_product',
          this.productVariant
        )
      } else {
        this.$cookies.set(this.customerIdentifier + '_product', this.serviceKey)
      }
      this.$cookies.set(
        this.customerIdentifier + '_license_size',
        this.licenseSize
      )
    },
    hasDaysUntilExpirationIncreased() {
      let prevDaysUntilExpiration = this.$cookies.get(
        this.customerIdentifier + '_days_until_expiration'
      )

      return (
        prevDaysUntilExpiration &&
        prevDaysUntilExpiration < this.daysUntilExpiration
      )
    },
    hasTrialBeenUpgraded() {
      let prevIsTrialVal = this.$cookies.get(
        this.customerIdentifier + '_is_trial'
      )
      return prevIsTrialVal === '1' && this.isTrial === false
    },
    hasProductChanged() {
      let prevProduct = this.$cookies.get(this.customerIdentifier + '_product')
      if (this.isUnified) {
        return prevProduct !== this.productVariant
      }
      return prevProduct !== this.serviceKey
    },
    hasLicenseSizeIncreased() {
      let prevLicenseSize = this.$cookies.get(
        this.customerIdentifier + '_license_size'
      )

      return prevLicenseSize && prevLicenseSize < this.licenseSize
    }
  }
}
</script>
<style lang="scss">
.the-header-nav {
  background: #ffffff;
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  top: 64px;
  transition: transform 300ms ease-in-out;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.3);
  z-index: 10002;
  transform: translateY(-105%);
  max-height: calc(100% - 64px);
  overflow-y: auto;

  a.close {
    display: none;
  }

  &.is-open {
    transform: translateY(0);
  }

  > .content-wrapper {
    padding: 15px 0;
    transition: min-height 300ms ease-in-out;
    min-height: 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 10px;
    margin-top: 0;
    color: $font-color;
  }

  .content {
    p {
      margin-bottom: 1px;
      color: $font-color;
      margin-top: 0;
    }
  }
}

@media (min-width: $desktop-min-width) {
  .nav-popup-open .the-header-nav .content-wrapper {
    min-height: 612px;
  }

  .the-header-nav {
    background: #f8f8f8;
    top: 100px;
    padding-bottom: 15px;

    a.close {
      position: absolute;
      right: 20px;
      top: 20px;
      display: inline-block;
      text-decoration: none;

      > span {
        vertical-align: middle;

        &:first-child {
          margin-right: 6px;
        }

        img {
          vertical-align: middle;
          width: 16px;
          height: 16px;
        }
      }
    }

    &.hide-on-desktop {
      display: none;
    }

    a {
      font-family: $default-fonts;
    }

    p,
    span {
      font-family: $default-fonts;
      color: $font-color;
    }

    .content {
      p {
        margin-bottom: 0;
        margin-top: 2px;
        font-size: 1.071rem;
        line-height: 1.42rem;
      }
    }

    .content-wrapper {
      max-width: 960px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: $desktop-min-width) and (max-width: 991px) {
  .the-header-nav {
    background: #f8f8f8;
  }

  .the-header-nav .content-wrapper {
    margin: 0 16px;
  }
}
</style>
