var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("picture", [
    _c("img", {
      staticClass: "logo",
      attrs: {
        src: _vm.logoPath,
        width: _vm.width,
        height: _vm.height,
        alt: _vm.customerPromotionAffiliate,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }