<template>
  <base-header-nav-product-link
    is-mobile-only
    is-simple
    :to="toUrl"
    :productName="productName"
    @click.native="closeTopMenuAndNavigate"
    ref="product-link"
    disabled
  />
</template>

<script>
import BaseHeaderNavProductLink from './BaseHeaderNavProductLink.vue'

export default {
  components: {
    BaseHeaderNavProductLink
  },
  props: {
    productShortName: {
      type: String,
      required: true
    }
  },
  computed: {
    couponShareProductNames() {
      return this.$store.state.ui.couponShareProductNames
    },
    toUrl() {
      return '/portal/coupon/share/' + this.$props.productShortName
    },
    productName() {
      return this.couponShareProductNames[this.$props.productShortName]
    }
  },
  methods: {
    closeTopMenuAndNavigate() {
      this.$router.push(this.$refs['product-link'].to)
      this.$root.$emit('toggle_popup', {
        elementId: 'top-menu',
        action: 'close'
      })
    }
  }
}
</script>
