<template>
  <section class="top" :class="{ 'not-active': $props.noTopLink }">
    <div>
      <router-link
        to="/"
        data-track-event="navigate"
        data-track-name="portal-welcome"
        id="lnk-top-logo"
        :event="$props.noTopLink ? '' : 'click'"
      >
        <span>My F-Secure</span>
      </router-link>
    </div>
    <div>
      <a href="https://www.f-secure.com/">
        <base-brand-logo />
      </a>
      <mobile-menu-link v-if="!noMenuLink" :isShown="isNavShown" />
    </div>
  </section>
</template>

<script>
import MobileMenuLink from './TheHeaderBarMobileMenuLink.vue'
import BaseBrandLogo from './BaseBrandLogo.vue'
export default {
  props: {
    isNavShown: {
      type: Boolean,
      default: false
    },
    noMenuLink: {
      type: Boolean,
      default: false
    },
    noTopLink: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MobileMenuLink,
    BaseBrandLogo
  }
}
</script>

<style lang="scss" scoped>
.not-active {
  a {
    cursor: auto;
  }
}
section {
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 0; /* ie11 */

  span {
    font-size: 18px;
    font-family: $default-fonts;
    color: $font-color;
  }

  > div:first-child {
    flex: 1;

    a {
      text-decoration: none;
    }
  }

  > div:last-child {
    padding-top: 3px;
    line-height: 14px;
  }

  picture {
    display: inline-block;
    line-height: 14px;

    img {
      max-height: 48px;
    }
  }
}

@media (min-width: $desktop-min-width) {
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    position: relative;
    z-index: 1;
    padding: 0;

    div:first-child {
      display: block;
    }

    div:last-child {
      display: block;
      padding-top: 0;
    }

    picture {
      display: inline-block;

      img {
        max-height: 42px;
      }
    }
  }
}
</style>
