export default {
  data() {
    return {
      isOpen: false,
      bodyClassOnOpen: undefined,
      elementAllowedToLand: undefined,
      eventInitiator: undefined,
      positionX: undefined,
      positionY: undefined,
      data: undefined
    }
  },
  props: {
    closeByEsc: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.bodyClassOnOpen = this.$el.dataset && this.$el.dataset.bodyClassOnOpen
    this.$root.$on('toggle_popup', this.onTogglePopup)
    this.$root.$on('move_popup', this.onMovePopup)
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    if (this.bodyClassOnOpen) {
      document.body.classList.remove(this.bodyClassOnOpen)
    }
    this.$root.$off('toggle_popup', this.onTogglePopup)
    this.$root.$off('move_popup', this.onMovePopup)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  deactivated() {
    if (this.bodyClassOnOpen) {
      document.body.classList.remove(this.bodyClassOnOpen)
    }
  },
  methods: {
    async beforeOpen() {},
    onBodyClick(event) {
      if (
        (this.$el.contains(event.target) &&
          event.target.dataset.closeOnClick !== 'true') ||
        (event.target.dataset.closeOnClick === 'true' &&
          event.target !== this.$el) ||
        event.target === this.eventInitiator ||
        (this.eventInitiator && this.eventInitiator.contains(event.target))
      ) {
        return
      }

      this.closePopup()
    },
    onKeyUp(event) {
      if (this.$props.closeByEsc) {
        this.closeOnEscPress(event)
      }
    },
    togglePopup() {
      this.toggle()
    },
    toggle() {
      if (this.isOpen) {
        this.onClose()
      } else {
        this.onOpen()
      }

      this.$root.$emit('toggle_popup_done', this.$el.id)
    },
    onToggleClick() {},
    openPopup() {
      this.onOpen()
    },
    async onOpen() {
      if (this.isOpen) {
        return
      }
      await this.beforeOpen()
      if (this.bodyClassOnOpen) {
        document.body.classList.add(this.bodyClassOnOpen)
      }
      window.addEventListener('click', this.onBodyClick)
      this.isOpen = true
      this.$emit('popup-opened', this.$el.id)
      this.$root.$emit('popup-opened', this.$el.id)
    },
    onClose() {
      if (!this.isOpen) {
        return
      }
      if (this.bodyClassOnOpen) {
        document.body.classList.remove(this.bodyClassOnOpen)
      }
      window.removeEventListener('click', this.onBodyClick)
      this.isOpen = false
      this.$emit('popup-closed', this.$el.id)
      this.$root.$emit('popup-closed', this.$el.id)
    },
    closePopup() {
      this.onClose()
    },
    closeOnEscPress(event) {
      if (this.isOpen && event.code === 'Escape') {
        this.closePopup()
      }
    },
    onMouseEnter(elementQuery) {
      if (elementQuery) {
        this.elementAllowedToLand = document.querySelector(elementQuery)
      }
      this.onOpen()
    },
    onMouseLeave(event) {
      if (
        this.elementAllowedToLand &&
        (event.relatedTarget === null ||
          event.relatedTarget === this.$el ||
          event.relatedTarget === this.elementAllowedToLand ||
          this.elementAllowedToLand.contains(event.relatedTarget) ||
          this.$el.contains(event.relatedTarget))
      ) {
        return
      }

      this.onClose()
    },
    onTogglePopup({ elementId, action, eventInitiator, event, data }) {
      if (this.$el.id !== elementId) {
        return
      }

      if (data) {
        this.data = data
      }

      if (eventInitiator) {
        this.eventInitiator = eventInitiator
      }

      if (action) {
        if (action === 'open' && !this.isOpen) {
          this.toggle()
        }

        if (action === 'close' && this.isOpen) {
          this.toggle()
        }

        if (action === 'mouseenter') {
          this.onMouseEnter('#' + eventInitiator.id)
        }

        if (action === 'mouseleave') {
          this.onMouseLeave(event)
        }
      } else {
        this.toggle()
      }
    },
    movePopup(x, y) {
      let popupRect = this.$el.getBoundingClientRect()
      let halfWidth = Math.round(popupRect.width / 2)

      if (x >= 0) {
        if (x >= halfWidth) {
          this.positionX = x - halfWidth
        } else {
          this.positionX = x
        }
      }

      if (y >= 0) {
        this.positionY = y
      }
    },
    onMovePopup({ elementId, positionX, positionY }) {
      if (this.$el.id !== elementId) {
        return
      }

      this.movePopup(positionX, positionY)
    }
  }
}
