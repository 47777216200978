var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-header-nav-product-link", {
    ref: "product-link",
    attrs: {
      "is-mobile-only": "",
      id: "dashboard-link-idp-monitor",
      to: "/portal/idp-portal",
      "data-track-event": "navigate",
      "data-track-name": "id-monitoring",
      "product-name": _vm.$t("dashboard.id_monitoring"),
      "product-cta": _vm.ctaKey,
      "product-ctas": _vm.ctas,
      showLicensesUsed: false,
      disabled: "",
      "is-dimmed": _vm.isDimmed,
    },
    nativeOn: {
      click: function ($event) {
        return _vm.openUpgradeOrClose($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }