var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "the-help",
      class: { "is-open": _vm.isOpen },
      style: { height: _vm.computedHeight },
      attrs: { "data-track-event": "navigate", "data-track-name": "fs-help" },
      on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave },
    },
    [
      _c("div", { staticClass: "content-wrapper" }, [
        _c("div", { staticClass: "links" }, [
          _c(
            "a",
            {
              attrs: {
                "data-track-event": "navigate",
                "data-track-name": "online-manuals",
                target: "_blank",
                rel: "noopener",
                href:
                  "https://help.f-secure.com/home-products.html#lang=" +
                  _vm.currentLocaleLanguage,
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("help.lnk_online_manuals")))])]
          ),
          _c(
            "a",
            {
              attrs: {
                "data-track-event": "navigate",
                "data-track-name": "contact-us",
                target: "_blank",
                rel: "noopener",
                href:
                  "https://www.f-secure.com/" +
                  _vm.fsecureWebLocale +
                  "/support/contact",
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("help.lnk_contact_us")) +
                    " / " +
                    _vm._s(_vm.$t("help.lnk_support"))
                ),
              ]),
            ]
          ),
          _c(
            "a",
            {
              attrs: {
                "data-track-event": "navigate",
                "data-track-name": "support-services",
                target: "_blank",
                rel: "noopener",
                href:
                  "https://www.f-secure.com/" +
                  _vm.fsecureWebLocale +
                  "/support",
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("help.lnk_support_services")))])]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "button",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClick.apply(null, arguments)
              },
            },
          },
          [
            _vm._m(0),
            _c("span", [_vm._v(_vm._s(_vm.$t("help.btn_f_secure_help")))]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon_customer_support.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }