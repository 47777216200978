<template>
  <base-header-nav-product-link
    :is-mobile-only="$props.isMobileOnly"
    id="dashboard-link-key"
    to="/portal/key"
    data-track-event="navigate"
    data-track-name="portal-key"
    :show-ribbon="false"
    :isHighlighted="isHighlighted"
    :product-name="productNames.key"
    :product-icon="icon"
    :product-description="productDescription"
    :product-cta="ctaKey"
    :product-ctas="ctas"
    disabled
    @click.native="openUpgradeOrClose($event)"
    :is-installed="isKeyInstalled"
    :licenses-available="keyAvailableLicenses"
    :licenses-total="keyTotalLicenses"
    :is-dimmed="isDimmed"
    :is-key-installable="isKeyInstallable"
    :cartType="convertCartType"
    :extraProductCta="extraCta"
    :extraProductCtas="extraCtas"
    extraProductDataTrackEvent="navigate"
    extraProductDataTrackName="id-monitoring"
    ref="product-link"
  >
  </base-header-nav-product-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseHeaderNavProductLink from './BaseHeaderNavProductLink.vue'

export default {
  props: {
    isMobileOnly: {
      type: Boolean
    }
  },
  components: {
    BaseHeaderNavProductLink
  },
  computed: {
    ...mapGetters({
      isKeyInstalled: 'customer/licenses/isKeyInstalled',
      productNames: 'ui/productNames',
      isKeyInstallable: 'customer/licenses/isKeyInstallable'
    }),
    ...mapState({
      keyTotalLicenses: (state) => state.customer.licenses.keyTotalLicenses,
      keyAvailableLicenses: (state) =>
        state.customer.licenses.keyAvailableLicenses,
      cart: (state) => state.ui.cart
    }),
    icon() {
      if (this.productNames.key === 'ID PROTECTION') {
        return require('@/assets/images/icons/icon_idp.svg')
      }
      return require('@/assets/images/icons/icon_key.svg')
    },
    ctaKey() {
      if (!this.isKeyInstallable) {
        return 'get'
      }

      if (!this.isKeyInstalled) {
        return 'install'
      }

      return 'manage'
    },
    showIDPWeb() {
      return this.$store.state.ui.showIDPWeb
    },
    showMonitoring() {
      return this.isKeyInstallable && this.showIDPWeb
    },
    productDescription() {
      if (this.productNames.key === 'ID PROTECTION') {
        return this.$t('dashboard.idp_card_desc')
      }
      return this.$t('dashboard.key_card_desc')
    },
    ctas() {
      return [
        {
          type: 'get',
          text: this.$t('dashboard.btn_get_key', {
            product_name: this.productNames.key
          })
        },
        {
          type: 'install',
          text: this.$t('dashboard.install_key', {
            product_name: this.productNames.key
          })
        },
        {
          type: 'manage',
          text: this.$t('dashboard.manage_key', {
            product_name: this.productNames.key
          })
        }
      ]
    },
    extraCta() {
      if (this.ctaKey !== 'get') {
        return 'monitor'
      } else {
        return null
      }
    },
    extraCtas() {
      return [
        {
          type: 'monitor',
          text: this.$t('dashboard.open_id_monitoring')
        }
      ]
    },
    isHighlighted() {
      return false
    },
    isDimmed() {
      return !this.isKeyInstallable
    },
    convertCartType() {
      if (this.cart.type) {
        return this.cart.type.replace('_', '-')
      }
      return ''
    }
  },
  methods: {
    openUpgradeOrClose($event) {
      if (
        this.showMonitoring &&
        ($event.srcElement.classList.contains('extra') ||
          $event.srcElement.parentElement.classList.contains('extra'))
      ) {
        // eslint-disable-next-line handle-callback-err
        this.$router.push('/portal/idp-portal').catch((err) => {})
        this.closeTopMenu()
        return
      }

      if (!this.isKeyInstallable) {
        this.openUpgradePopup()
        return
      }

      // eslint-disable-next-line handle-callback-err
      this.$router.push(this.$refs['product-link'].to).catch((err) => {})
      this.closeTopMenu()
    },
    openUpgradePopup() {
      if (this.cart.direct_to_cart) {
        window.location.href = this.cart.direct_to_cart
        return
      }
      this.$root.$emit('toggle_popup', {
        elementId: 'upgrade-popup',
        action: 'open',
        eventInitiator: this.$el
      })
    },
    closeTopMenu() {
      this.$root.$emit('toggle_popup', {
        elementId: 'top-menu',
        action: 'close'
      })
    }
  }
}
</script>
