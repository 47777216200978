<template>
  <router-link
    class="nav-row"
    :class="{
      'is-highlighted': $props.isHighlighted,
      'is-dimmed': $props.isDimmed,
      'is-mobile-only': $props.isMobileOnly,
      'is-simple': $props.isSimple
    }"
    :to="$props.to"
    :event="$props.disabled ? '' : 'click'"
  >
    <div
      class="ribbon-wrapper"
      v-if="!$props.isMobileOnly"
      v-show="$props.showRibbon"
    >
      <div class="ribbon">
        <span
          v-html="$t('dashboard.not_yet_installed', { line_break: '<br>' })"
        ></span>
      </div>
    </div>
    <div
      class="content"
      :class="{ 'no-description': !$props.productDescription }"
      :data-track-event="dataTrackEvent"
      :data-track-name="dataTrackName"
    >
      <h5>
        <span class="title">{{ $props.productName }}</span>
        <span
          class="small-text"
          v-show="isMobileInstallSuggestionShown && !$props.isSimple"
        >
          {{ $t('dashboard.install_now') }}</span
        >
      </h5>
      <div class="img-wrapper" v-if="!$props.isMobileOnly">
        <picture>
          <img
            :src="$props.productIcon"
            :alt="$props.productName"
            :data-track-event="dataTrackEvent"
            :data-track-name="dataTrackName"
          />
        </picture>
      </div>
      <p
        v-show="!$props.isInstalled && !$props.isSimple"
        class="description"
        :class="{ empty: !$props.productDescription }"
        :data-track-event="dataTrackEvent"
        :data-track-name="dataTrackName"
      >
        {{ $t($props.productDescription) }}
      </p>
      <p
        v-show="$props.isInstalled && !$props.isSimple"
        v-if="$props.licensesTotal && $props.showlicensesAvailable"
        class="description"
        :data-track-event="dataTrackEvent"
        :data-track-name="dataTrackName"
      >
        {{ $t('dashboard.x_licenses_remaining', { x: licensesAvailableText }) }}
      </p>
    </div>
    <div class="bottom" v-show="!$props.isSimple">
      <img src="@/assets/images/icons/dashboard_icon_arrow_right.svg" alt />
      <span
        class="button"
        v-if="!$props.isMobileOnly"
        :data-track-event="dataTrackEvent"
        :data-track-name="dataTrackName"
      >
        <span
          v-for="cta in $props.productCtas"
          :key="cta.type"
          v-show="$props.productCta === cta.type"
          class="text"
          >{{ cta.text }}</span
        >
      </span>
      <span
        class="button extra"
        v-if="!$props.isMobileOnly && $props.extraProductCta && showIDPWeb"
        :data-track-event="$props.extraProductDataTrackEvent"
        :data-track-name="$props.extraProductDataTrackName"
      >
        <span
          v-for="cta in $props.extraProductCtas"
          :key="cta.type"
          v-show="$props.extraProductCta === cta.type"
          :id="cta.type"
          class="text extra"
          :data-track-event="$props.extraProductDataTrackEvent"
          :data-track-name="$props.extraProductDataTrackName"
          >{{ cta.text }}</span
        >
      </span>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    isHighlighted: {
      type: Boolean,
      default: false
    },
    productName: {
      type: String
    },
    productIcon: {
      type: String
    },
    productDescription: {
      type: String
    },
    productCta: {
      type: String
    },
    productCtas: {
      type: Array
    },
    isInstalled: {
      type: Boolean
    },
    licensesAvailable: {
      type: Number
    },
    licensesTotal: {
      type: Number
    },
    isSafeInstallable: {
      type: Boolean
    },
    isFreedomeInstallable: {
      type: Boolean
    },
    isKeyInstallable: {
      type: Boolean
    },
    showRibbon: {
      type: Boolean
    },
    showlicensesAvailable: {
      type: Boolean,
      default: true
    },
    to: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    isDimmed: {
      type: Boolean
    },
    isMobileOnly: {
      type: Boolean
    },
    isSimple: {
      type: Boolean
    },
    extraProductCta: {
      type: String
    },
    extraProductCtas: {
      type: Array
    },
    extraProductDataTrackEvent: {
      type: String
    },
    extraProductDataTrackName: {
      type: String
    },
    extraTo: {
      type: String
    },
    cartType: {
      type: String
    }
  },
  computed: {
    licensesAvailableText() {
      return this.$props.licensesAvailable + ' / ' + this.$props.licensesTotal
    },
    isMobileInstallSuggestionShown() {
      return (
        this.$props.isHighlighted &&
        !this.$props.isInstalled &&
        this.$props.licensesTotal > 0
      )
    },
    showIDPWeb() {
      return this.$store.state.ui.showIDPWeb
    },
    dataTrackEvent() {
      if (
        this.$props.to === '/portal/welcome' ||
        this.$props.to === '/portal/freedome' ||
        this.$props.to === '/portal/key' ||
        this.$props.to === '/portal/idp-portal'
      ) {
        return 'navigate'
      }
      return ''
    },
    dataTrackName() {
      if (this.$props.to === '/portal/welcome') {
        return 'portal-fsecure'
      }
      if (this.$props.to === '/portal/freedome') {
        return 'portal-freedome'
      }
      if (this.$props.to === '/portal/key') {
        return 'portal-key'
      }
      if (this.$props.to === '/portal/idp-portal') {
        return 'id-monitoring'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
a.nav-row {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;

  &.wider {
    > div.ribbon-wrapper {
      > div.ribbon {
        right: -17%;
        top: 10.5%;
      }
    }
  }

  h5 {
    font-family: $default-fonts;
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 1px;
    padding-bottom: 0;
    color: #000;
    margin-bottom: 0;
    line-height: 1.21886em;
    padding-top: 0.288em;

    span {
      &.title {
        font-size: 0.9em;
        color: #000;
      }
      &.small-text {
        color: $primary-color;
      }
    }
  }

  &.is-dimmed {
    opacity: 0.5;
    -webkit-user-select: none;
  }

  &.is-last-card {
    border-bottom-color: #cccccc;
    padding-bottom: 16px;
  }

  &.is-simple {
    padding: 15px 30px;

    &:first-child {
      border-top: 1px solid #e8e8e8;
    }

    h5 {
      padding-top: 0;
      margin: 0;
    }
  }

  &:nth-child(2) {
    padding-top: 16px;
  }

  > div.ribbon-wrapper {
    display: none;
  }

  > div.content {
    padding-right: 15px;
    width: 100%;

    &.no-description {
      padding-top: 7px;
      padding-bottom: 6px;
    }

    > .img-wrapper {
      display: none;
    }
  }

  > .bottom {
    flex: 1 0 auto;
  }

  img {
    vertical-align: middle;
  }

  div:last-child span {
    display: none;
  }

  .description.empty {
    padding-top: 5px;
  }
}

@media (min-width: $desktop-min-width) {
  a.nav-row {
    text-align: center;
    margin-right: 16px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    flex-direction: column;
    padding: 0;
    overflow: visible;
    position: relative;
    flex: 1;
    -ms-flex-flow: row wrap;
    align-content: space-between;

    &.is-mobile-only {
      display: none;
    }

    &:nth-child(2) {
      padding-top: 0;
    }

    &.is-dimmed {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);

      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
        cursor: auto;
      }
    }

    &.is-inactive {
      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
        cursor: auto;
        -webkit-user-select: none;
      }
    }

    > div.ribbon-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      display: block;

      > div.ribbon {
        position: absolute;
        background: #ffcc00;
        width: 70%;
        transform: rotate(45deg);
        right: -16%;
        top: 6.5%;
        padding: 5px 0;
        border-radius: 4px;
        display: block;

        > span {
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          display: block;
          line-height: 16px;
        }
      }
    }

    &:hover {
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.6);
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-last-card {
      margin-right: 0;
      padding-bottom: 0;
    }

    &.is-highlighted {
      div:last-child {
        background-color: $primary-color;

        span {
          color: #fff;
        }
      }
    }

    h5 {
      padding: 0;
      margin-top: 0;
      font-size: 1.786rem;
      font-weight: 500;
      line-height: 1em;
      margin-bottom: 0;
    }

    > div.content {
      padding: 27px 30px 38px 30px;

      > .img-wrapper {
        height: 126px;
        padding: 26px 0 0 0;
        display: inline-block;
      }
    }

    > div.bottom {
      border-top: 1px solid #f2f2f2;
      width: 100%;
      margin-bottom: -1px;
      border-radius: 0 0 6px 6px;
      flex: none;

      .button {
        + .button {
          border-top: 1px solid #f2f2f2;
          width: 100%;
        }
      }

      > img {
        display: none;
      }

      span {
        display: inline-block;
        line-height: 50px;
        font-weight: bold;
        color: $primary-color;
        font-size: 1rem;
      }
    }

    .img-wrapper picture {
      display: inline-block;
      width: 76px;
      height: 76px;
    }

    h5 span.small-text {
      display: none;
    }
  }
}
</style>
