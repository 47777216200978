<template>
  <base-header-nav-row class="logo mobile-only">
    <myfs-logo />
  </base-header-nav-row>
</template>

<script>
import BaseHeaderNavRow from './BaseHeaderNavRow.vue'
import MyfsLogo from './BaseBrandLogo.vue'

export default {
  components: {
    MyfsLogo,
    BaseHeaderNavRow
  }
}
</script>

<style lang="scss" scoped>
.nav-row {
  &.logo {
    text-align: center;
    padding-top: 7px;

    picture {
      display: inline-block;
      max-height: 38px;
      margin-bottom: 7px;

      img {
        vertical-align: bottom;
      }
    }
  }
}
</style>
