var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "top", class: { "not-active": _vm.$props.noTopLink } },
    [
      _c(
        "div",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: "/",
                "data-track-event": "navigate",
                "data-track-name": "portal-welcome",
                id: "lnk-top-logo",
                event: _vm.$props.noTopLink ? "" : "click",
              },
            },
            [_c("span", [_vm._v("My F-Secure")])]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "a",
            { attrs: { href: "https://www.f-secure.com/" } },
            [_c("base-brand-logo")],
            1
          ),
          !_vm.noMenuLink
            ? _c("mobile-menu-link", { attrs: { isShown: _vm.isNavShown } })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }