<template>
  <header
    class="the-header-bar"
    :class="{
      'no-shadow': $props.noShadow,
      wide: $props.isWide,
      'even-wider': $props.isEvenWider,
      'nav-shown': $props.isNavShown
    }"
  >
    <the-header-bar-first-section
      :isNavShown="$props.isNavShown"
      :no-menu-link="noSecondSection"
      :no-top-link="noTopLink"
    />
    <the-header-bar-second-section v-if="showSecondSection" />
  </header>
</template>
<script>
// import { mapGetters } from 'vuex'
import TheHeaderBarFirstSection from './TheHeaderBarFirstSection.vue'
const TheHeaderBarSecondSection = () =>
  import(/* webpackChunkName: "views" */ './TheHeaderBarSecondSection.vue')
export default {
  data() {
    return {
      resizing: false,
      isActive: false
    }
  },
  props: {
    noShadow: {
      type: Boolean,
      default: false
    },
    isWide: {
      type: Boolean,
      default: false
    },
    isEvenWider: {
      type: Boolean,
      default: false
    },
    isNavShown: {
      type: Boolean,
      default: false
    },
    noSecondSection: {
      type: Boolean,
      default: false
    },
    noTopLink: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TheHeaderBarFirstSection,
    TheHeaderBarSecondSection
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.customer.isLoggedIn
    },
    showSecondSection() {
      return !this.$props.noSecondSection && this.isLoggedIn
    }
  },
  methods: {
    sendHeightChanged() {
      if (!this.isActive) {
        return
      }
      this.$nextTick(() => {
        if (!this.isActive) {
          return
        }
        this.$root.$emit(
          'header-height-changed',
          this.$el.getBoundingClientRect().height
        )
        this.resizing = false
      })
    },
    handleResize: function a() {
      if (this.resizing) {
        return
      }

      this.resizing = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.sendHeightChanged)
      } else {
        window.setTimeout(this.sendHeightChanged, 66)
      }
    }
  },
  activated() {
    this.isActive = true
    this.sendHeightChanged()
    window.addEventListener('resize', this.handleResize)
  },
  deactivated() {
    this.isActive = false
    window.removeEventListener('resize', this.handleResize)
  },
  updated() {
    this.sendHeightChanged()
  }
}
</script>
<style lang="scss" scoped>
body:not(.top-menu-open) .the-header-bar.no-shadow {
  box-shadow: none;
}

.the-header-bar {
  background: #fff;
  font-family: $default-fonts;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  z-index: 10003;
  position: fixed;
  width: 100%;
  top: 0;

  &.wide {
    > section {
      max-width: 980px;
    }
  }

  &.even-wider {
    > section {
      max-width: 1018px;
    }
  }

  &.nav-shown {
    > section:first-child {
      ::v-deep picture {
        display: none;
      }
    }
  }

  a {
    font-family: $default-fonts;
  }

  p {
    color: $font-color;
  }

  > section {
    min-height: 64px;
  }
}

@media (min-width: $desktop-min-width) {
  .the-header-bar {
    &.nav-shown {
      > section:first-child {
        ::v-deep picture {
          display: inline-block;
        }
      }
    }

    > section {
      min-height: 50px;
      max-width: 960px;
      margin: 0 auto;
    }
  }
}

@media (min-width: $desktop-min-width) and (max-width: 991px) {
  .the-header-bar > section {
    margin: 0 16px;
  }
}
</style>
