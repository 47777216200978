var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-header-nav-row",
    { staticClass: "grid mobile-only" },
    [
      !_vm.isSubUser
        ? _c(
            "router-link",
            {
              attrs: {
                id: "mobile-subscription-link",
                "data-track-event": "navigate",
                "data-track-name": "subscription",
                to: "/portal/order",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeTopMenu.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("dashboard.lnk_subscription")))])]
          )
        : _vm._e(),
      _c(
        "a",
        {
          attrs: {
            id: "mobile-support-link",
            "data-track-event": "navigate",
            "data-track-name": "support-services",
            href:
              "https://www.f-secure.com/" + _vm.fsecureWebLocale + "/support",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("dashboard.lnk_support_services")))])]
      ),
      _c(
        "router-link",
        {
          attrs: {
            id: "mobile-account-link",
            "data-track-event": "navigate",
            "data-track-name": "account-details",
            to: "/portal/contact",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.closeTopMenu.apply(null, arguments)
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("dashboard.lnk_account")))])]
      ),
      _c(
        "a",
        {
          attrs: {
            id: "mobile-terms-of-use-link",
            "data-track-event": "navigate",
            "data-track-name": "terms-of-use",
            href:
              "https://www.f-secure.com/" +
              _vm.fsecureWebLocale +
              "/legal/terms/websites",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("dashboard.lnk_terms_of_use")))])]
      ),
      _c(
        "a",
        {
          attrs: {
            id: "mobile-privacy-link",
            "data-track-event": "navigate",
            "data-track-name": "privacy-terms",
            href:
              "https://www.f-secure.com/" +
              _vm.fsecureWebLocale +
              "/legal/privacy/websites",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("dashboard.lnk_privacy_terms")))])]
      ),
      _c(
        "a",
        {
          staticClass: "icon-link black-to-gray-image dark-text",
          attrs: {
            id: "mobile-language-link",
            href: "#",
            "data-track-event": "navigate",
            "data-track-name": "language-menu",
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.openRegionPopup.apply(null, arguments)
            },
          },
        },
        [
          _c("span", [
            _c("span", { staticClass: "icon h24" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/icon-places.svg"),
                  alt: "",
                },
              }),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.currentLocaleName))]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }