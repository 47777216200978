var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "the-region-picker",
      class: { visible: _vm.isOpen },
      style: { left: _vm.positionXStyle },
      attrs: {
        id: "region-popup",
        "data-body-class-on-open": "region-popup-open",
      },
      on: {
        mouseenter: function ($event) {
          return _vm.onMouseEnter("#region-popup")
        },
        mouseleave: function ($event) {
          return _vm.onMouseLeave($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "content-wrapper",
          attrs: { "data-ng-controller": "RegionPickerCtrl" },
        },
        [
          _c(
            "a",
            {
              staticClass: "popup-close",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closePopup.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "15",
                    height: "12.5",
                    version: "1.1",
                    viewBox: "0 0 5.2917 5.2917",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("g", { attrs: { transform: "translate(0 -291.71)" } }, [
                    _c("path", {
                      attrs: {
                        transform: "matrix(.26458 0 0 .26458 0 291.71)",
                        d: "m0 0v0.70703l9.293 9.293-9.293 9.293v0.70703h0.70703l9.293-9.293 9.293 9.293h0.70703v-0.70703l-9.293-9.293 9.293-9.293v-0.70703h-0.70703l-9.293 9.293-9.293-9.293h-0.70703z",
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "head" }, [
            _c("h5", [
              _vm._v(_vm._s(_vm.$t("region_picker.hl_choose_your_language"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.availableLocales, function (locale) {
              return _c(
                "a",
                {
                  key: locale.locale,
                  class: {
                    selected: _vm.selectedLocale.locale === locale.locale,
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onLanguageClick(locale)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(locale.name) + " ")]
              )
            }),
            0
          ),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "medium primary btn" }, [
              _c(
                "a",
                {
                  staticClass: "flex-btn primary",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onChangeClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("region_picker.btn_change_language"))),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }