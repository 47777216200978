<template>
  <div class="nav-row">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.nav-row {
  padding: 10px 16px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

@media (min-width: $desktop-min-width) {
  .nav-row {
    flex: 1;
    -ms-flex-flow: row wrap;
    align-content: space-between;

    &.mobile-only {
      display: none !important;
    }
  }
}
</style>
