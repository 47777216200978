var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-header-nav-product-link", {
    ref: "product-link",
    attrs: {
      "is-mobile-only": _vm.$props.isMobileOnly,
      id: "dashboard-link-key",
      to: "/portal/key",
      "data-track-event": "navigate",
      "data-track-name": "portal-key",
      "show-ribbon": false,
      isHighlighted: _vm.isHighlighted,
      "product-name": _vm.productNames.key,
      "product-icon": _vm.icon,
      "product-description": _vm.productDescription,
      "product-cta": _vm.ctaKey,
      "product-ctas": _vm.ctas,
      disabled: "",
      "is-installed": _vm.isKeyInstalled,
      "licenses-available": _vm.keyAvailableLicenses,
      "licenses-total": _vm.keyTotalLicenses,
      "is-dimmed": _vm.isDimmed,
      "is-key-installable": _vm.isKeyInstallable,
      cartType: _vm.convertCartType,
      extraProductCta: _vm.extraCta,
      extraProductCtas: _vm.extraCtas,
      extraProductDataTrackEvent: "navigate",
      extraProductDataTrackName: "id-monitoring",
    },
    nativeOn: {
      click: function ($event) {
        return _vm.openUpgradeOrClose($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }