<template>
  <div
    class="base-view"
    :class="{
      'blue-bg': isBlueBgView,
      'top-menu-open': isTopMenuOpen,
      'no-top-bottom-padding': noTopBottomPadding,
      overlayed
    }"
  >
    <div class="top" ref="top">
      <slot name="top" />
    </div>
    <div class="bottom" ref="bottom">
      <slot name="bottom" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isBlueBgView: {
      type: Boolean,
      default: false
    },
    noTopBottomPadding: {
      type: Boolean,
      default: false
    },
    overlayed: {
      type: Boolean
    }
  },
  computed: {
    isTopMenuOpen() {
      return this.$store.state.ui.isTopMenuOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.base-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.blue-bg {
    > .top {
      background-image: url('../assets/images/bg-horizontal-combined.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.overlayed {
      > .top {
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),
          url('../assets/images/bg-horizontal-combined.jpg');
      }
    }
  }

  &.no-top-bottom-padding {
    > .top {
      padding-bottom: 0;
    }
  }

  > .top {
    position: relative;
    flex: 1 0 auto;
    background: #f5f5f5;
    padding-bottom: 100px;
  }

  > .bottom {
    flex: 0 0 auto;
  }
}

@media (min-width: $desktop-min-width) {
  .base-view {
    > .top {
      padding-bottom: 0;
    }
    > .bottom {
      position: relative;
    }
  }
}
</style>
