var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-header-nav-product-link", {
    ref: "product-link",
    attrs: {
      "is-mobile-only": "",
      "is-simple": "",
      to: _vm.toUrl,
      productName: _vm.productName,
      disabled: "",
    },
    nativeOn: {
      click: function ($event) {
        return _vm.closeTopMenuAndNavigate.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }