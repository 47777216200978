<template>
  <footer
    class="the-footer"
    :class="{ 'visible-on-mobile': isVisibleOnMobile }"
  >
    <a
      data-track-event="navigate"
      data-track-name="terms-of-use"
      :href="
        'https://www.f-secure.com/' + fsecureWebLocale + '/legal/terms/websites'
      "
      >{{ $t('footer.lnk_terms_of_use') }}</a
    >
    <a
      data-track-event="navigate"
      data-track-name="privacy-terms"
      :href="
        'https://www.f-secure.com/' +
        fsecureWebLocale +
        '/legal/privacy/websites'
      "
      >{{ $t('footer.lnk_privacy_terms') }}</a
    >
    <a
      ref="languageLink"
      data-track-event="navigate"
      data-track-name="language-menu"
      href="#"
      class="icon-link"
      @touchstart="setTouchTrue"
      @mouseenter="onLanguageMouseEnter($event, '#region-popup')"
      @mouseleave="onLanguageMouseLeave"
      @click.prevent="onLanguageClick"
    >
      <span>
        <span class="icon">
          <img src="@/assets/images/icons/icon-places-blue.svg" alt />
        </span>
        <span>{{ currentLocaleName }}</span>
      </span>
    </a>
  </footer>
</template>

<script>
export default {
  props: {
    isVisibleOnMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      languageElem: undefined,
      resizing: false,
      isTouch: false
    }
  },
  computed: {
    currentLocaleName() {
      return this.$store.state.ui.currentLocaleName
    },
    fsecureWebLocale() {
      const MYFS_TO_FSECURE_WEBPAGE_MAPPINGS = JSON.parse(
        process.env.VUE_APP_MYFS_TO_FSECURE_WEBPAGE_MAPPINGS
      )
      const locale = this.$store.state.ui.currentLocale
      return MYFS_TO_FSECURE_WEBPAGE_MAPPINGS[locale]
    }
  },
  mounted() {
    this.languageElem = document.querySelector('#region-popup')
  },
  activated() {
    window.addEventListener('resize', this.onResize)
    this.onResizeFrame()
  },
  deactivated() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    setTouchTrue() {
      this.isTouch = true
    },
    onLanguageMouseEnter(event) {
      if (
        (event.sourceCapabilities &&
          event.sourceCapabilities.firesTouchEvents) ||
        this.isTouch
      ) {
        return
      }
      this.toggleAndMoveRegionPopup(event, 'open')
    },
    onLanguageMouseLeave(event) {
      if (
        this.languageElem &&
        (event.relatedTarget === this.languageElem ||
          this.languageElem.contains(event.relatedTarget))
      ) {
        return
      }
      this.toggleAndMoveRegionPopup(event, 'close')
    },
    onLanguageClick(event) {
      this.toggleAndMoveRegionPopup(event)
    },
    toggleAndMoveRegionPopup(event, action) {
      this.emitMovePopup()
      this.$root.$emit('toggle_popup', {
        elementId: 'region-popup',
        action,
        eventInitiator: this.$refs.languageLink
      })
      if (window.innerWidth < 835) {
        this.$nextTick(() => {
          window.scrollTo(0, 0)
        })
      }
    },
    emitMovePopup() {
      let popupPositionX = 0

      if (window.innerWidth > 835) {
        let linkClientRect = this.$refs.languageLink.getBoundingClientRect()
        popupPositionX = Math.round(
          linkClientRect.left + linkClientRect.width / 2
        )
      }

      this.$root.$emit('move_popup', {
        elementId: 'region-popup',
        positionX: popupPositionX,
        positionY: undefined
      })
    },
    onResize() {
      if (this.resizing) {
        return
      }

      this.resizing = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.onResizeFrame)
      } else {
        setTimeout(this.onResizeFrame, 66)
      }
    },
    onResizeFrame() {
      this.emitMovePopup()
      this.resizing = false
    }
  }
}
</script>

<style lang="scss" scoped>
$footer-fonts: 'Segoe UI', 'Helvetica Neue', 'San Francisco', 'Roboto';

.the-footer {
  display: none;
  background: #fff;
  text-align: center;
  position: relative;
  z-index: 2;

  &.visible-on-mobile {
    display: block;
  }

  a {
    display: inline-block;
    line-height: 45px;
    margin-right: 15px;
    text-decoration: none;
    font-family: 'FSecureOffice';

    &:hover {
      color: $font-color;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 25px;
      background: #f2f2f2;
      vertical-align: middle;
      margin-left: 14px;
      margin-top: -5px;
    }

    &:last-child {
      margin-right: 0;

      &::after {
        content: none;
      }
    }
  }
}

@media (min-width: $desktop-min-width) {
  .the-footer {
    display: block;
  }
}
</style>
