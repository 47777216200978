<template>
  <div
    id="region-popup"
    class="the-region-picker"
    :class="{ visible: isOpen }"
    :style="{ left: positionXStyle }"
    data-body-class-on-open="region-popup-open"
    @mouseenter="onMouseEnter('#region-popup')"
    @mouseleave="onMouseLeave($event)"
  >
    <div class="content-wrapper" data-ng-controller="RegionPickerCtrl">
      <a href="#" class="popup-close" @click.prevent="closePopup">
        <svg
          width="15"
          height="12.5"
          version="1.1"
          viewBox="0 0 5.2917 5.2917"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(0 -291.71)">
            <path
              transform="matrix(.26458 0 0 .26458 0 291.71)"
              d="m0 0v0.70703l9.293 9.293-9.293 9.293v0.70703h0.70703l9.293-9.293 9.293 9.293h0.70703v-0.70703l-9.293-9.293 9.293-9.293v-0.70703h-0.70703l-9.293 9.293-9.293-9.293h-0.70703z"
            />
          </g>
        </svg>
      </a>
      <div class="head">
        <h5>{{ $t('region_picker.hl_choose_your_language') }}</h5>
      </div>
      <div class="content">
        <a
          v-for="locale in availableLocales"
          :key="locale.locale"
          href="#"
          :class="{ selected: selectedLocale.locale === locale.locale }"
          @click.prevent="onLanguageClick(locale)"
        >
          {{ locale.name }}
        </a>
      </div>
      <div class="footer">
        <div class="medium primary btn">
          <a class="flex-btn primary" href="#" @click.prevent="onChangeClick">
            <span>{{ $t('region_picker.btn_change_language') }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import popupMixin from '@/mixins/popup.js'

export default {
  mixins: [popupMixin],
  data() {
    return {
      selectedLocale: { locale: 'en_US', name: 'United States' }
    }
  },
  mounted() {
    this.$root.$on('current-locale-updated', this.updateSelectedLocale)
    this.updateSelectedLocale()
  },
  beforeDestroy() {
    this.$root.$off('current-locale-updated', this.updateSelectedLocale)
  },
  computed: {
    availableLocales() {
      return this.$store.state.ui.availableLocales
    },
    positionXStyle() {
      return this.positionX + 'px'
    },
    loadedLanguages() {
      return this.$store.state.ui.loadedLanguages
    }
  },
  methods: {
    updateSelectedLocale() {
      this.selectedLocale = {
        locale: this.$store.state.ui.currentLocale,
        name: this.$store.state.ui.currentLocaleName
      }
    },
    onLanguageClick(locale) {
      this.selectedLocale = locale
    },
    setLocaleFromSelection(selectedLocale) {
      this.$cookies.set('myfs_locale', selectedLocale.locale, '1y')
      this.$store.commit('ui/SET_CURRENT_LOCALE', selectedLocale.locale)
      this.$store.commit('ui/SET_CURRENT_LOCALE_NAME', selectedLocale.name)
      this.$root.$emit('current-locale-updated')
    },
    onChangeClick() {
      const lang = this.selectedLocale.locale.split('_')[0]

      if (this.$i18n.locale === lang) {
        this.setLocaleFromSelection(this.selectedLocale)
        this.closePopup()
        return
      }

      this.getAsyncLanguage(lang).then((response) => {
        if (response) {
          this.$i18n.setLocaleMessage(lang, response.default)
        }
        this.$store.commit('ui/ADD_LOADED_LANGUAGE', lang)
        this.$i18n.locale = lang
        this.setLocaleFromSelection(this.selectedLocale)
        this.closePopup()
      })
    },
    getAsyncLanguage(lang) {
      if (this.loadedLanguages.includes(lang)) {
        return Promise.resolve()
      }

      return import(
        /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.region-popup-open::before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transition: opacity ease-in-out 200ms, visibility ease-in-out 200ms;
}
.the-region-picker {
  position: absolute;
  bottom: 0;
  padding: 10px 10px 0 10px;
  top: 64px;
  left: 0;
  width: 100%;
  position: absolute;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out,
    max-height 200ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 10003;
  overflow: hidden;
  height: calc(100vh - 64px);

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  > .content-wrapper {
    width: 100%;
    height: calc(100% - 12px);
    padding: 16px;
    overflow: visible;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &::after {
      content: none;
      display: block;
      height: 20px;
      width: 20px;
      position: absolute;
      background: #fff;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%) rotate(45deg);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
      clip-path: polygon(-20% 120%, 120% -20%, 120% 120%);
    }

    &::before {
      content: '';
      width: 50%;
      background: #fff;
      height: 20px;
      bottom: 0;
      position: absolute;
      left: 25%;
      z-index: 1;
    }

    .popup-close {
      position: absolute;
      right: 0;
      padding: 15px 20px;
      top: 0;
    }

    .head {
      padding-bottom: 23px;

      h5 {
        padding: 0;
        padding-right: 20px;
        font-size: 1.5rem;
        font-family: 'FSecureOffice';
        font-weight: 300;
        margin: 0;
      }
    }

    .content {
      height: 100%;
      overflow-y: scroll;

      a {
        display: inline-block;
        width: 100%;
        text-decoration: none;
        font-size: 1.15rem;
        padding: 2px 0;
        color: #000;

        &.selected {
          padding-left: 15px;
          position: relative;
          color: $primary-color;
          font-weight: 600;
          vertical-align: text-top;

          &:before {
            content: '';
            width: 4px;
            background: $primary-color;
            height: 100%;
            position: absolute;
            left: 0;
            margin-top: -3px;
          }
        }
      }
    }

    .footer {
      padding-top: 23px;
      position: relative;
      z-index: 2;

      div.btn {
        width: 100%;
        height: 50px;
        margin-right: 0;

        a {
          line-height: auto;
          font-family: $default-fonts;
          font-size: 1.142rem;
          font-weight: 600;
        }
      }
    }
  }
}

@media (min-width: $desktop-min-width) {
  .the-region-picker {
    width: auto;
    top: auto;
    z-index: 2;
    padding: 10px 10px 22px 10px;
    max-height: 50px;
    height: auto;
    bottom: 45px;

    &.visible {
      max-height: 579px;
    }

    > .content-wrapper {
      width: 279px;
      height: 506px;

      &::after {
        content: '';
      }
    }
  }
}
</style>
